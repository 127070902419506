.loading {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  background-color: #133256;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  margin: 0;
  padding: 0;
}
