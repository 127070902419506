@font-face {
  font-family: "FiraSans-Regular";
  src: url("./Fonts/FiraSans/FiraSans-Regular.ttf");
}

@font-face {
  font-family: "FiraSans-Bold";
  src: url("./Fonts/FiraSans/FiraSans-Bold.ttf");
}

@font-face {
  font-family: "Gilroy-Regular";
  src: url("./Fonts/Gilroy/Gilroy-Regular.otf");
}

@font-face {
  font-family: "Gilroy-Bold";
  src: url("./Fonts/Gilroy/Gilroy-Bold.otf");
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Gilroy-Bold", bold;
}

body,
p,
div {
  font-family: "FiraSans-Regular", sans-serif;
}
