.imageItem {
  height: 100%;
  width: 100%;
}

.imageContainer {
  width: 100%;
  height: 100%;
}

.image {
  object-fit: contain;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.imageBackground {
  width: 100%;
  height: 100%;
  position: absolute;
  filter: blur(35px) brightness(1.7);
  transform: scale(3.03);
}

.logoContainer {
  display: flex;
  flex-flow: row-reverse;
}

.logo {
  height: 4.2rem;
  width: auto;
  z-index: 20;
}
