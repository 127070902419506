.videoItem {
  width: 100%;
}

.videoContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #000;
  display: flex;
  justify-content: center;
}
