.alert {
  padding: 5%;
}

.textArea {
  color: #f00;
  background-color: #fff;
  width: 90%;
  height: auto;
  padding: 5%;
}

.title {
  font-size: 1.8vw;
  font-weight: 600;
  line-height: 1;

  /* Overrid default top margin for h2 */
  margin-block-start: 0;
  margin-block-end: 0.5em;
}

.body {
  font-size: 1.5vw;
}
