.previewContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #f24d2d;
  box-shadow: 0 0 0 999px #f24d2d;
  clip-path: inset(0 -100%);
  inset: 0 auto auto 0;
  transform-origin: 100% 0;
  transform: translate(-29.3%) rotate(-45deg);
  color: #fff;
  z-index: 20;
}
