.rssItem {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.logoContainer {
  margin-top: 5px;
  margin-right: 5px;
  display: flex;
  flex-flow: row-reverse;
}

.logo {
  height: 8vh;
  width: auto;
}

.logoAndTitleContainer {
  display: flex;
  align-items: center;
  animation: slideInFromLeft 1s ease-in-out;
}

.logoContainerForWebkitLineClampPadding {
  min-width: 25px;
  background: linear-gradient(to right, #133256 50%, #84b8d0 50%);
  position: absolute;
  top: 0;
  bottom: 0;
  box-sizing: border-box;
}

.titleContainerForWebkitLineClampPadding {
  margin-right: 3vw;
  margin-bottom: 4vh;
  margin-left: 3vw;
  background-color: #e5e5e5;
  min-height: 80px;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.title {
  margin: 5px 5px 5px 30px;
  padding: 5px;
  font-size: 3.5vh;
  color: #133256;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  position: relative;
  z-index: 1;
  animation: slideInFromRight 1s ease-in-out;
}

@media only screen and (min-width: 1281px) {
  .logoContainerForWebkitLineClampPadding {
    width: 60px;
  }

  .titleContainerForWebkitLineClampPadding {
    min-height: 200px;
  }

  .titleContainerForWebkitLineClampPadding .title {
    margin: 10px 10px 10px 100px;
  }
}

@media only screen and (max-width: 1920px) {
  .titleContainerForWebkitLineClampPadding .title {
    font-size: 3.3vw;
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
