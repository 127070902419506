.imageItem {
  height: 100vh;
  width: 100vw;
}

.imageContainer {
  width: 100%;
  height: 100%;
}

.image {
  object-fit: contain;
  z-index: 10;
  position: absolute;
  width: 100%;
  height: 100%;
}

.imageBackground {
  width: 100%;
  height: 100%;
  position: absolute;
  filter: blur(35px) brightness(1.7);
  transform: scale(1.03);
}
