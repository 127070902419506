.errorArea {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(../Assets/error-area-background.jpeg);
  background-size: cover;
}

.content {
  width: 60vw;
  text-align: center;
  font-size: 2.4vmax;
  color: #fff;
}

.logo {
  width: 47%;
}

.separator {
  width: 47%;
  height: 1px;
  margin: 2.8vmax auto;
  padding: 0;
  border: none;
  background-color: #acaeb1;
}

.message,
.resolution {
  margin: 0.8vmax;
  padding: 0;
}
