.rssItem {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.logoContainer {
  display: flex;
  flex-flow: row-reverse;
}

.logo {
  height: 6vh;
  width: auto;
}

.titleContainerForWebkitLineClampPadding {
  margin: 0;
  padding: 2vh 2vw;
  background-color: rgba(0, 48, 87, 0.8);
}

.title {
  margin: 0;
  padding: 0;
  font-size: 4.5vh;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  animation: slideInFromRight 1s ease-in-out;
}

.descriptionContainerForWebkitLineClampPadding {
  margin: 0;
  padding: 2vh 2vw;
  background-color: rgba(255, 255, 255, 0.85);
}

.description {
  margin: 0;
  padding: 0;
  font-size: 3.8vh;
  color: #133256;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  animation: slideInFromLeft 1s ease-in-out;
}

@media only screen and (min-width: 375px) {
  .title {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    font-size: 4vh;
  }

  .description {
    display: -webkit-box;
    font-size: 3.3vh;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  @keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
}
