.bigNotice {
  height: 100%;
  width: 100%;
  background-color: #fff;
}

.titleContainerForWebkitLineClampPadding {
  margin: 0;
  padding: 2.5vh 2.5vw;
  background-color: #f04d28;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  margin: 0;
  padding: 0;
  font-size: 3.5vmax;
  color: #fff;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.body {
  margin: 4.5vh 2.5vw;
  white-space: pre-wrap;
  font-size: 2.3vmax;
  line-height: 3.5vmax;
  color: #133256;
}

.imageContainer {
  width: 100%;
  height: 100%;
  position: relative;
}

.image {
  object-fit: contain;
  z-index: 10;
  position: absolute;
  width: 100%;
  height: 100%;
}

.imageBackground {
  width: 100%;
  height: 100%;
  position: absolute;
  filter: blur(35px) brightness(1.7);
  transform: scale(1.03);
}

@media only screen and (max-height: 350px) {
  .title {
    font-size: 15vh;
    -webkit-line-clamp: 1;
  }

  .titleContainerForWebkitLineClampPadding {
    height: 35vh;
  }

  .body {
    overflow: hidden;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    font-size: 13vh;
    line-height: 16vh;
  }
}
